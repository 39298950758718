<template>
  <div class="uk-container uk-margin-small-top">
    <h2>Conference Videos <span>({{ videos.length }})</span></h2>
    <div class="uk-grid">
      <div class="uk-width-3-4@m">
        <div class="uk-card uk-card-default uk-card-body uk-card-small">
          <table class="uk-table uk-table-divider">
            <tbody>
              <tr v-for="(video, i) in videos" :key="`video-${i}`">
                <td width="60%">
                  <router-link
                    :to="{
                      name: 'trader.conference.show',
                      params: { id: video.id },
                    }"
                  >
                    <p
                      :class="`text-dark uk-margin-remove ${
                        loading ? 'skeleton skeleton-text' : ''
                      }`"
                    >
                      {{ video.title }}
                    </p>
                    <p class="skeleton skeleton-text" v-if="loading"></p>
                  </router-link>
                </td>
                <td>
                  <span :class="loading ? 'skeleton skeleton-text' : ''">{{ $localizer.parse(video.date) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="uk-width-1-4@m">
        <div class="uk-card uk-card-default uk-card-body uk-card-small">
          <h3>Continue Learning</h3>
          <p>There are more videos in the academy.</p>
          <router-link
            :to="{ name: 'trader.academy.index' }"
            class="uk-button uk-button-primary"
            >Visit Academy</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      videos: [{}, {}, {}, {}, {}],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/trader/conference").then((response) => {
        this.videos = response.data.videos;
        this.loading = false;
      });
    },
  },
};
</script>
